import elon from './bestsocial.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={elon} alt={"Ol' Musky"} />
        <marquee>Touching grass is the best social network</marquee>
        <p>(p2w.social is a 100% serverless edge-delivered application)</p>
        <p>Brought to you by <a href="https://discord.gg/p2wfactions">the best discord</a></p>
      </header>
    </div>
  );
}

export default App;
